var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['team/index',
                                           'team-categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Team', icon: 'nc-icon nc-badge'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['team/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Team', path: '/team/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['team-categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Category', path: '/team-categories/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['publications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Publications', icon: 'nc-icon nc-paper-2', path: '/publications'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['faq/index']))?_c('sidebar-item',{attrs:{"link":{name: 'FAQ', icon: 'nc-icon nc-caps-small', path: '/faq'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['pages/index',
                                           'content-pages/create',
                                           'redirection-pages/create',
                                           'branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['pages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['panel-groups/index','panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                          // || $store.getters['auth/checkAccess']('permissions/index')
                          || _vm.$store.getters['auth/checkAccess']('users/index')
                          || _vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Deleted Users', path: '/users/DeletedUsersList'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }