import DashboardLayout from "@/pages/layouts/DashboardLayout";

let teamRoutes = {
  path: '/team',
  component: DashboardLayout,
  redirect: '/team/list',
  children: [
    {
      path: 'list',
      name: 'Team List',
      component: () => import(/* webpackChunkName: "team" */  '@/pages/team/TeamList'),
      meta: {
        permissionsCodes: ['team/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Team',
      component: () => import(/* webpackChunkName: "team" */  '@/pages/team/TeamForm'),
      meta: {
        permissionsCodes: ['team/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Team',
      component: () => import(/* webpackChunkName: "team" */  '@/pages/team/TeamForm'),
      meta: {
        permissionsCodes: ['team/update'],
      }
    }
  ]
}

export default teamRoutes;
