<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>

        <div class="copyright">
          Template Dashboard
        </div>

      </nav>
      <div class="copyright">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by
        <a href="http://www.dlimits.com" target="_blank">dlimits</a>.
      </div>
    </div>
  </footer>
</template>

<script>
  export default {}

</script>
<style>

</style>
