import DashboardLayout from "@/pages/layouts/DashboardLayout";

let faqRoutes = {
  path: '/faq',
  component: DashboardLayout,
  redirect: '/faq/list',
  children: [
    {
      path: 'list',
      name: 'FAQ List',
      component: () => import(/* webpackChunkName: "faq" */  '@/pages/faq/FAQList'),
      meta: {
        permissionsCodes: ['faq/index'],
      }
    },
    {
      path: 'create',
      name: 'Add FAQ',
      component: () => import(/* webpackChunkName: "faq" */  '@/pages/faq/FAQForm'),
      meta: {
        permissionsCodes: ['faq/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit FAQ',
      component: () => import(/* webpackChunkName: "faq" */  '@/pages/faq/FAQForm'),
      meta: {
        permissionsCodes: ['faq/update'],
      }
    }
  ]
}

export default faqRoutes;
