import DashboardLayout from "@/pages/layouts/DashboardLayout";

let teamCategoryRoutes = {
  path: '/team-categories',
  component: DashboardLayout,
  redirect: '/team-categories/list',
  children: [
    {
      path: 'list',
      name: 'Team Category List',
      component: () => import(/* webpackChunkName: "Team-categories" */  '@/pages/team/teamCategories/TeamCategoryList'),
      meta: {
        permissionsCodes: ['team-categories/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Team Category',
      component: () => import(/* webpackChunkName: "team-categories" */  '@/pages/team/teamCategories/TeamCategoryForm'),
      meta: {
        permissionsCodes: ['team-categories/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Team Category',
      component: () => import(/* webpackChunkName: "team-categories" */  '@/pages/team/teamCategories/TeamCategoryForm'),
      meta: {
        permissionsCodes: ['team-categories/update'],
      }
    }
  ]
}

export default teamCategoryRoutes;
